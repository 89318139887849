export default function AthenaParameterTransformation (
    queryName,
    params = {},
    filters = {}
  ) {
  
    Object.keys(params).forEach(key => {
      if (params[key] === -1 || params[key] === "-1")  {
        params[key] = ''
      }
    })
    Object.keys(filters).forEach(key => {
      if (filters[key] === -1 || filters[key] === "-1") {
        filters[key] = ''
      }
    })
    switch (queryName) {
      //     case 'activity_read':
      //       return {
      //         lookupCol: params.lookupCol ? params.lookupCol : '',
      //         id: params.id ? params.id : '',
      //       }
      case 'actrans_by_activity_read_serverside':
        return {
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          period: params.period ? params.period : '',
          company: params.company ? params.company : '',
          activity: params.activity ? params.activity : '',
          activity_grp: params.activityGrp ? params.activityGrp : '',
          acct_category: params.acctCategory ? params.acctCategory : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : ''
        }
      case 'actrans_by_activity_read_serverside_download':
        return {
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          period: params.period ? params.period : '',
          company: params.company ? params.company : '',
          activity: params.activity ? params.activity : '',
          activity_grp: params.activityGrp ? params.activityGrp : '',
          acct_category: params.acctCategory ? params.acctCategory : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : '',
          searchComp: filters.COMPANY ? filters.COMPANY : '',
          searchYear: filters.FISCAL_YEAR ? filters.FISCAL_YEAR : '',
          searchPeriod: filters.PERIOD ? filters.PERIOD : '',
          searchActivity: filters.ACTIVITY ? filters.ACTIVITY : '',
          searchActivityGrp: filters.ACTIVITY_GRP ? filters.ACTIVITY_GRP : '',
          searchAcctCat: filters.ACCT_CATEGORY ? filters.ACCT_CATEGORY : '',
          searchAccUnit: filters.ACCT_UNIT ? filters.ACCT_UNIT : '',
          searchAccount: filters.ACCOUNT ? filters.ACCOUNT : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : ''
        }
      case 'actrans_read_serverside':
        return {
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          period: params.period ? params.period : '',
          company: params.company ? params.company : '',
          activity: params.activity ? params.activity : '',
          activity_grp: params.activityGrp ? params.activityGrp : '',
          acct_category: params.acctCategory ? params.acctCategory : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : ''
        }
      case 'actrans_read_serverside_download':
        return {
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          period: params.period ? params.period : '',
          company: params.company ? params.company : '',
          activity: params.activity ? params.activity : '',
          activity_grp: params.activityGrp ? params.activityGrp : '',
          acct_category: params.acctCategory ? params.acctCategory : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : '',
          searchComp: filters.COMPANY ? filters.COMPANY : '',
          searchYear: filters.FISCAL_YEAR ? filters.FISCAL_YEAR : '',
          searchPeriod: filters.PERIOD ? filters.PERIOD : '',
          searchActivity: filters.ACTIVITY ? filters.ACTIVITY : '',
          searchActivityGrp: filters.ACTIVITY_GRP ? filters.ACTIVITY_GRP : '',
          searchAcctCat: filters.ACCT_CATEGORY ? filters.ACCT_CATEGORY : '',
          searchAccUnit: filters.ACCT_UNIT ? filters.ACCT_UNIT : '',
          searchAccount: filters.ACCOUNT ? filters.ACCOUNT : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : ''
        }
      case 'am_am70_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_lse_company: params.leaseCompany ? params.leaseCompany : '',
          prm_lease: params.lease ? params.lease : '',
          prm_location: params.location ? params.location : '',
          prm_division: params.division ? params.division : '',
          prm_asset_type: params.assetType ? params.assetType : '',
          prm_sub_type: params.subType ? params.subType : '',
          prm_asset_grp: params.assetGroup ? params.assetGroup : ''
        }
      case 'am_am80_read_serverside_v3':
        return {
          prm_company: params.company ? params.company : '',
          prm_book: params.book ? params.book : '',
          prm_asset: params.asset ? params.asset : '',
          prm_process: params.process ? params.process : '',
          prm_lease_company: params.leaseCompany ? params.leaseCompany : '',
          prm_lease: params.lease ? params.lease : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : ''
        }
      case 'am_am80_read_serverside_v3_download':
        return {
          prm_company: params.company ? params.company : '',
          prm_book: params.book ? params.book : '',
          prm_asset: params.asset ? params.asset : '',
          prm_process: params.process ? params.process : '',
          prm_lease_company: params.leaseCompany ? params.leaseCompany : '',
          prm_lease: params.lease ? params.lease : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : '',
          searchDateFrom: filters.TRANS_DATE_FROM ? filters.TRANS_DATE_FROM : '',
          searchDateTo: filters.TRANS_DATE_TO ? filters.TRANS_DATE_TO : '',
          searchBook: filters.BOOK ? filters.BOOK : '',
          searchCompany: filters.COMPANY ? filters.COMPANY : '',
          searchProcess: filters.ASSET_PROCESS ? filters.ASSET_PROCESS : '',
          searchAsset: filters.ASSET_LINK ? filters.ASSET_LINK : '',
          searchRef: filters.REFERENCE ? filters.REFERENCE : '',
          searchAccount: filters.ACCOUNT ? filters.ACCOUNT : '',
          searchAccUnit: filters.ACCT_UNIT ? filters.ACCT_UNIT : ''
        }
      case 'am_asset_read':
        return {
          prm_asset: params.asset,
          prm_company: params.company
        }
      case 'am_book_read':
        return {
          prm_asset: params.asset
        }
      case 'am_item_read':
        return {
          prm_asset: params.asset
        }
      case 'ap_invoice_read_serverside_v2':
        return {
          prm_vendor: params.vendor,
          prm_ven_grp: params.vendorGroup
        }
      case 'ap_invoice_read_serverside_v2_download':
        return {
          prm_vendor: params.vendor ? params.vendor : '',
          prm_ven_grp: params.vendorGroup ? params.vendorGroup : '',
          searchDateFrom: filters.INVOICE_DATE_FROM
            ? filters.INVOICE_DATE_FROM
            : '',
          searchDateTo: filters.INVOICE_DATE_TO ? filters.INVOICE_DATE_TO : '',
          searchInvoice: filters.INVOICE ? filters.INVOICE : '',
          searchVoucherNumber: filters.VOUCHER_NBR ? filters.VOUCHER_NBR : '',
          searchCompany: filters.COMPANY ? filters.COMPANY : '',
          searchPo: filters.PO_NUMBER ? filters.PO_NUMBER : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : ''
        }
      case 'ap_payment_from_cb_read':
        return {
          prm_pay_group: params.payGroup ? params.payGroup : '',
          prm_inst_code: params.instCode ? params.instCode : '',
          prm_trans_id: params.transId ? params.transId : ''
        }
      case 'ap_payment_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_vendor: params.vendor ? params.vendor : '',
          prm_invoice: params.invoice ? params.invoice : ''
        }
      case 'ap_vendor_main_read':
        return {
          prm_ven_grp: params.vendorGroup,
          prm_vendor: params.vendor
        }
      case 'ap_venloc_read':
        return {
          prm_ven_grp: params.vendorGroup,
          prm_vendor: params.vendor
        }
      case 'ar_customer_main_read':
        return {
          prm_company: params.company,
          customer_param: params.customer
        }
      case 'ar_distrib_read':
        return {
          prm_company: params.company,
          prm_batch: params.batch,
          prm_type: params.type,
          prm_invoice: params.invoice,
          prm_customer: params.customer
        }
      case 'ar_invoice_read':
        return {
          prm_company: params.company,
          prm_customer: params.customer
        }
      case 'ar_payment_read':
        return {
          prm_company: params.company,
          prm_invoice: params.invoice
        }
      case 'aroitems_read':
        return params
      case 'autocomplete_actrans':
        return params
      case 'autocomplete_actrans_activity':
        return params
      case 'autocomplete_actrans_years':
        return params
      case 'autocomplete_ar_customer':
        return params
      case 'autocomplete_ar_customer_company':
        return params
      case 'autocomplete_aroitems':
        return params
      case 'autocomplete_asset':
        return params
      case 'autocomplete_asset_id':
        return params
      case 'autocomplete_bill_cost_activity':
        return params
      case 'autocomplete_budget':
        return params
      case 'autocomplete_buyer':
        return params
      case 'autocomplete_cashcode':
        return params
      case 'autocomplete_company':
        return params
      case 'autocomplete_employee':
        return params
      case 'autocomplete_gl_trans_v3':
        return params
      case 'autocomplete_gl_trans_years_v3':
        return params
      case 'autocomplete_item':
        return params
      case 'autocomplete_item_location':
        return params
      case 'autocomplete_item_location_item':
        return params
      case 'autocomplete_po':
        return params
      case 'autocomplete_prdistrib':
        return params
      case 'autocomplete_reports_select':
        return params
      case 'autocomplete_requisition_company':
        return params
      case 'autocomplete_requisition_req':
        return params
      case 'autocomplete_transaction_nbr':
        return params
      case 'autocomplete_trial_balance':
        return params
      case 'autocomplete_vendor':
        return params
      case 'autocomplete_vendor_location':
        return params
      case 'benefits_read':
        return {
          in_company: params.company,
          in_employee: params.empId
        }
      case 'bill_of_costs':
        return params
      case 'bill_of_costs_ac_params':
        return {
          prm_fiscal_year: params.fiscalYear ? params.fiscalYear : '',
          prm_period: params.period ? params.period : '',
          prm_company: params.company ? params.company : '',
          prm_activity_group: params.activityGrp ? params.activityGrp : '',
          prm_activity: params.activity ? params.activity : '',
          prm_date_from: params.fromDate ? params.fromDate : '',
          prm_date_to: params.toDate ? params.toDate : ''
        }
      case 'bill_of_costs_ac_params_download':
        return {
          prm_fiscal_year: params.fiscalYear ? params.fiscalYear : '',
          prm_period: params.period ? params.period : '',
          prm_company: params.company ? params.company : '',
          prm_activity_group: params.activityGrp ? params.activityGrp : '',
          prm_activity: params.activity ? params.activity : '',
          prm_date_from: params.fromDate ? params.fromDate : '',
          prm_date_to: params.toDate ? params.toDate : ''
        }
      case 'budget_read':
        return {
          prm_company: params.company,
          prm_fiscal_year: params.fiscalYear,
          prm_budget_nbr: params.budgetNumber,
          prm_acct_unit: params.accountUnit,
          prm_account: params.account,
          prm_sub_account: params.subAccount
        }
      case 'cash_payment_inquiry_serverside':
        return {
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : ''
        }
      case 'cash_payment_inquiry_serverside_download':
        return {
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : '',
          search_company: filters.COMPANY ? filters.COMPANY : '',
          search_cash_code: filters.CASH_CODE ? filters.CASH_CODE : '',
          search_tran_code: filters.BANK_INST_CODE ? filters.BANK_INST_CODE : '',
          search_source: filters.SOURCE_CODE ? filters.SOURCE_CODE : '',
          status_num: filters.REC_STATUS ? filters.REC_STATUS : '',
          search_trans_nbr: filters.TRANS_NBR ? filters.TRANS_NBR : '',
          search_trans_ident: filters.TRANS_IDENT ? filters.TRANS_IDENT : ''
        }
      case 'cashbook_trans_serverside':
        return {
          prm_trans_nbr: params.transNbr ? params.transNbr : '',
          prm_cash_code: params.cashCode ? params.cashCode : '',
          prm_tran_code: params.bankCode ? params.bankCode : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : ''
        }
      case 'cashbook_trans_serverside_download':
        return {
          prm_trans_nbr: params.transNbr ? params.transNbr : '',
          prm_cash_code: params.cashCode ? params.cashCode : '',
          prm_tran_code: params.bankCode ? params.bankCode : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : '',
          searchCashCode: filters.CASH_CODE ? filters.CASH_CODE : '',
          searchPayment: filters.BANK_INST_CODE ? filters.BANK_INST_CODE : '',
          searchSource: filters.SOURCE_CODE ? filters.SOURCE_CODE : '',
          searchStat: filters.REC_STATUS ? filters.REC_STATUS : '',
          searchComp: filters.COMPANY ? filters.COMPANY : '',
          searchDateFrom: filters.ISSUE_DATE_FROM ? filters.ISSUE_DATE_FROM : '',
          searchDateTo: filters.ISSUE_DATE_TO ? filters.ISSUE_DATE_TO : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : ''
        }
      case 'cb_distrib_read':
        return {
          prm_inst_code: params.instCode ? params.instCode : '',
          prm_trans_id: params.transId ? params.transId : ''
        }
      case 'cbpayment_read':
        return params
      case 'cbpayment_read_serverside':
        return params
      case 'deduction_summary_get':
        return {
          in_company: params.company,
          in_employee: params.empId,
          PYEAR: params.year
        }
      case 'dependent_benefit_read':
        return {
          in_company: params.company,
          in_employee: params.empId
        }
      case 'dependent_read':
        return {
          in_company: params.company,
          in_employee: params.empId
        }
      case 'emp_deduction_read':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'emp_payroll_year_read':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'employee_certifications_read':
        return {
          in_company: params.company,
          in_employee: params.empId
        }
      case 'employee_checks_read':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'employee_main_read':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'employee_reviews_read':
        return {
          in_company: params.company,
          in_employee: params.empId
        }
      case 'employee_validation':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'get_meta_dtl':
        return params
      case 'get_meta_hdrs':
        return params
      case 'gl_chart_acounts_read':
        return {
          chartname: params.chartName ? params.chartName : ''
        }
      case 'gl_charts_read':
        return {
          chartname: params.chartName ? params.chartName : ''
        }
      case 'gl_trans_read_serverside_v3':
        return {
          company: params.company ? params.company : '',
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          fromPeriod: params.acctPeriodFrom ? params.acctPeriodFrom : '',
          toPeriod: params.acctPeriodTo ? params.acctPeriodTo : '',
          system: params.system ? params.system : '',
          accountUnit: params.accountUnit ? params.accountUnit : '',
          acct: params.account ? params.account : '',
          subAccount: params.subAccount ? params.subAccount : '',
          includeIntercompany: params.includeIntercompany
            ? params.includeIntercompany
            : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : '',
          searchAccUnit: filters.ACCT_UNIT ? filters.ACCT_UNIT : '',
          searchAccount: filters.ACCOUNT ? filters.ACCOUNT : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : '',
          searchControlGroup: filters.CONTROL_GROUP ? filters.CONTROL_GROUP : '',
          searchSystemCode: filters.SYSTEM ? filters.SYSTEM : '',
          searchJEType: filters.JE_TYPE ? filters.JE_TYPE : ''
        }
      case 'gl_trans_read_serverside_v3_download':
        return {
          company: params.company ? params.company : '',
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          fromPeriod: params.acctPeriodFrom ? params.acctPeriodFrom : '',
          toPeriod: params.acctPeriodTo ? params.acctPeriodTo : '',
          system: params.system ? params.system : '',
          accountUnit: params.accountUnit ? params.accountUnit : '',
          acct: params.account ? params.account : '',
          subAccount: params.subAccount ? params.subAccount : '',
          includeIntercompany: params.includeIntercompany
            ? params.includeIntercompany
            : '',
          prm_from_date: params.fromDate ? params.fromDate : '',
          prm_to_date: params.toDate ? params.toDate : '',
          searchAccUnit: filters.ACCT_UNIT ? filters.ACCT_UNIT : '',
          searchAccount: filters.ACCOUNT ? filters.ACCOUNT : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : '',
          searchControlGroup: filters.CONTROL_GROUP ? filters.CONTROL_GROUP : '',
          searchSystemCode: filters.SYSTEM ? filters.SYSTEM : '',
          searchJEType: filters.JE_TYPE ? filters.JE_TYPE : ''
        }
      case 'gl_trial_balance_consolidated':
        return {
          prm_period: params.period ? params.period : '',
          prm_year: params.fiscalYear ? params.fiscalYear : '',
          prm_company: params.company ? params.company : '',
          prm_trans_type: params.type ? params.type : ''
        }
      case 'gl_trial_balance_serverside':
        return {
          prm_year: params.fiscalYear ? params.fiscalYear : '',
          prm_period: params.period ? params.period : '',
          prm_company: params.company ? params.company : '',
          prm_hide_zero: params.hideZeroRows ? params.hideZeroRows : 0,
          prm_acct_unit: params.acctUnit ? params.acctUnit : '',
          prm_account: params.account ? params.account : '',
          prm_sub_acct: params.subAccount ? params.subAccount : ''
        }
      case 'gl_trial_balance_serverside_download':
        return {
          prm_year: params.fiscalYear ? params.fiscalYear : '',
          prm_period: params.period ? params.period : '',
          prm_company: params.company ? params.company : '',
          prm_hide_zero: params.hideZeroRows ? params.hideZeroRows : 0,
          prm_acct_unit: params.acctUnit ? params.acctUnit : '',
          prm_account: params.account ? params.account : '',
          prm_sub_acct: params.subAccount ? params.subAccount : '',
          searchAcctUnit: filters.ACCT_UNIT ? filters.ACCT_UNIT : '',
          searchAccount: filters.ACCT ? filters.ACCT : '',
          searchAcctUnitDesc: filters.ACCT_DESC ? filters.ACCT_DESC : '',
          searchDesc: filters.AU_DESC ? filters.AU_DESC : ''
        }
      case 'hrhistory_read':
        return params
      case 'ictrans_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_location: params.location ? params.location : '',
          prm_item: params.item ? params.item : ''
        }
      case 'invoice_dist_read':
        return {
          objId: params.objId ? params.objId : ''
        }
      case 'invoice_lookup':
        return params
      case 'invoice_payment_read':
        return params
      case 'invoices_by_vendor_read':
        return params
      case 'item_loc_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_location: params.location ? params.location : '',
          prm_item: params.item ? params.item : ''
        }
      case 'item_read':
        return {
          prm_item_group: params.itemGroup ? params.itemGroup : '',
          prm_item: params.item ? params.item : ''
        }
      case 'jc_attach_read':
        return params
      case 'journal_control':
        return {
          company: params.company ? params.company : '',
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          acctPeriod: params.acctPeriod ? params.acctPeriod : '',
          searchSystem: filters.SYSTEM ? filters.SYSTEM : '',
          searchType: filters.JE_TYPE ? filters.JE_TYPE : '',
          searchGroup: filters.CONTROL_GROUP ? filters.CONTROL_GROUP : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : '',
          searchOperator: filters.OPERATOR ? filters.OPERATOR : ''
        }
      case 'journal_control_download':
        return {
          company: params.company ? params.company : '',
          fiscalYear: params.fiscalYear ? params.fiscalYear : '',
          acctPeriod: params.acctPeriod ? params.acctPeriod : '',
          searchSystem: filters.SYSTEM ? filters.SYSTEM : '',
          searchType: filters.JE_TYPE ? filters.JE_TYPE : '',
          searchGroup: filters.CONTROL_GROUP ? filters.CONTROL_GROUP : '',
          searchDesc: filters.DESCRIPTION ? filters.DESCRIPTION : '',
          searchOperator: filters.OPERATOR ? filters.OPERATOR : ''
        }
      case 'padict_fields_read':
        return params
      case 'pay_rate_hist_read':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'paystub_ded_read':
        return params
      case 'paystub_distrib_read':
        return params
      case 'paystub_wage_read':
        return params
      case 'pers_act_detail_read':
        return params
      case 'pers_act_read':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'po_by_vendor_read_serverside':
        return {
          prm_vendor_group: params.vendorGroup,
          prm_vendor: params.vendor
        }
      case 'po_by_vendor_read_serverside_download':
        return {
          prm_vendor_group: params.vendorGroup,
          prm_vendor: params.vendor,
          searchComp: filters.COMAPNY ? filters.COMAPNY : '',
          searchPo: filters.PO_NUMBER ? filters.PO_NUMBER : '',
          searchBuyer: filters.BUYER_CODE ? filters.BUYER_CODE : '',
          searchPoCode: filters.PO_CODE ? filters.PO_CODE : '',
          searchDateFrom: filters.PO_DATE_FROM ? filters.PO_DATE_FROM : '',
          searchDateTo: filters.PO_DATE_TO ? filters.PO_DATE_TO : ''
        }
      case 'po_inquiry':
        return params
      case 'po_inquiry_serverside':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_date_begin: params.fromDate ? params.fromDate : '',
          prm_po_date_end: params.toDate ? params.toDate : '',
          prm_vendor_group: params.vendorGroup ? params.vendorGroup : '',
          prm_vendor: params.vendor ? params.vendor : '',
          prm_vendor_location: params.vendorLoc ? params.vendorLoc : '',
          prm_buyer_code: params.buyer ? params.buyer : '',
          prm_status: params.status ? params.status : '',
          prm_po_code: params.poCode ? params.poCode : '',
          prm_po_number: params.poNumber ? params.poNumber : '',
          prm_total_amount: params.totalAmt ? params.totalAmt : '',
          prm_total_lines: params.totalLines ? params.totalLines : ''
        }
      case 'po_inquiry_serverside_download':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_date_begin: params.fromDate ? params.fromDate : '',
          prm_po_date_end: params.toDate ? params.toDate : '',
          prm_vendor_group: params.vendorGroup ? params.vendorGroup : '',
          prm_vendor: params.vendor ? params.vendor : '',
          prm_vendor_location: params.vendorLoc ? params.vendorLoc : '',
          prm_buyer_code: params.buyer ? params.buyer : '',
          prm_status: params.status ? params.status : '',
          prm_po_code: params.poCode ? params.poCode : '',
          prm_po_number: params.poNumber ? params.poNumber : '',
          prm_total_amount: params.totalAmt ? params.totalAmt : '',
          prm_total_lines: params.totalLines ? params.totalLines : '',
          searchCompany: filters.COMPANY ? filters.COMPANY : '',
          searchVendor: filters.VENDOR ? filters.VENDOR : '',
          searchLocation: filters.LOCATION ? filters.LOCATION : '',
          searchPoNumber: filters.PO_NUMBER ? filters.PO_NUMBER : '',
          searchBuyerCode: filters.BUYER_CODE ? filters.BUYER_CODE : '',
          searchStatus: filters.STATUS ? filters.STATUS : ''
        }
      case 'po_line_source_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_number: params.poNum ? params.poNum : '',
          prm_po_release: params.poRelease ? params.poRelease : '',
          prm_po_code: params.poCode ? params.poCode : '',
          prm_line_nbr: params.lineNbr ? params.lineNbr : ''
        }
      case 'po_lookup':
        return params
      case 'po_receipt_inquiry':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_number: params.poNumber ? params.poNumber : '',
          prm_po_code: params.poCode ? params.poCode : '',
          prm_po_release: params.poRelease ? params.poRelease : 0,
          prm_date_begin: params.fromDate ? params.fromDate : '',
          prm_date_end: params.toDate ? params.toDate : ''
        }
      case 'po_receive_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_code: params.code ? params.code : '',
          prm_po_release: params.release ? params.release : '',
          prm_po_number: params.po ? params.po : ''
        }
      case 'po_recline_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_rec_number: params.recNum ? params.recNum : ''
        }
      case 'po_releases_per_po':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_code: params.code ? params.code : '',
          prm_po: params.po ? params.po : ''
        }
      case 'po_vendor_main_read':
        return params
      case 'poline_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_code: params.code ? params.code : '',
          prm_po_release: params.release ? params.release : '',
          prm_po: params.po ? params.po : ''
        }
      case 'prdistrib_read_serverside':
        return params
      case 'prdistrib_read_serverside_download':
        return params
      case 'print_manager_read':
        return params
      case 'purchorder_read':
        return {
          prm_company: params.company ? params.company : '',
          prm_po_code: params.code ? params.code : '',
          prm_po_release: params.release ? params.release : '',
          prm_po: params.po ? params.po : ''
        }
      case 'query':
        return params
      case 'reports_read':
        return params
      case 'rq_header_read':
        return {
          prm_company: params.company,
          prm_req_number: params.req
        }
      case 'rq_line_read':
        return {
          prm_company: params.company,
          prm_req_number: params.req
        }
      case 'user_field_read':
        return {
          comp_in: params.company,
          EMPID: params.empId
        }
      case 'vendor_group_read':
        return params
      case 'wage_summary_get':
        return {
          comp_in: params.company,
          EMPID: params.empId,
          PYEAR: params.year
        }
      default:
        return params
    }
  }
  