import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import LambdaFetch from '../functions/FetchFromLambda';
import { debounce } from 'throttle-debounce';
import { Box, IconButton } from '@mui/material';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { v4 as uuidv4 } from 'uuid';
import CustomFooter from '../utils/CustomDataTableFooter';
import moment from 'moment';
import AthenaParameterTransformation from '../utils/AthenaParameterTransformation'

const ReactTableServerSide = (props) => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [initalRender, setInitalRender] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(csvOptions);
  useEffect(() => {
    if (initalRender) return;
    debounceFetchTableData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, pagination.pageSize, pagination.pageIndex, sorting]);

  const getFilters = () => {

    const filters = columnFilters.reduce((acc, cur, index) => {
    acc[cur['id']] = cur.value ? cur.value : '';
    // TODO
    for (const sf in props.separatedDateFilters) {
      if (cur['id'] === sf) {
        const beginDateName = props.separatedDateFilters[sf][0];
        const endDateName = props.separatedDateFilters[sf][1];
        acc[beginDateName] = cur.value[0]
          ? cur.value[0].format(moment.HTML5_FMT.DATE)
          : '';
        acc[endDateName] = cur.value[1]
          ? cur.value[1].format(moment.HTML5_FMT.DATE)
          : '';
      }
    }
    return acc;
  }, {});

  const hasInvalidDate = Object.values(filters).includes("Invalid Date");
  if (hasInvalidDate) {
    return false;
  } else {
    return filters;
  }
}
  const fetchData = async () => {
    const filters = getFilters()
    if(!filters) return null
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    try {

      const resp = await LambdaFetch(
        props.apiResource,
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: props.action,
          params: props.params,
          columns: props.tableColumns,
          page: pagination.pageIndex,
          //sortOrder: sort,
          entriesPerPage: pagination.pageSize,
          filters,
        }),
        '',
        props.fetchInitialData.credentials
      );
      let tableData = props.transform
        ? props.transform(resp.data.tableData)
        : resp.data.tableData;

      setData(tableData);
      setInitalRender(false);
      if (props.callback) {
        props.callback();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      props.callback(null, error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const debounceFetchTableData = debounce(1000, false, () => {
    fetchData();
  });

  const handleExportData = () => {
    if(props.athenaDownload) {
      DownloadAthenaData();
    } else {
      DownloadData();
    }
  };
  const removePropertiesWithArrays = (obj) => {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        delete obj[key];
      }
    }
    return obj;
  }
  
  const DownloadData = async () => {
    try {
      const filters = removePropertiesWithArrays(getFilters())
      if(!filters) {
        props.fetchInitialData.createSnack('There is an invalid filter value', 'error',5000)
        return null
      }
      // const filters = columnFilters.reduce((acc, cur, index) => {
      //   acc[cur['id']] = cur.value ? cur.value : null;
      //   return acc;
      // }, {});

      LambdaFetch(
        props.apiResource,
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify({
          action: props.downloadAction,
          params: props.params,
          filters,
          docLink: uuidv4(),
        }),
        '',
        props.fetchInitialData.credentials,
        true,
        true
      );
      props.fetchInitialData.createSnack(
        'File sent to print manager',
        'success',
        3000
      );
    } catch (e) {
      console.log(e);
    }
  };

  const DownloadAthenaData = async () => {
    const filters = getFilters(true)

    if (!filters) return null
    try {
      const params = AthenaParameterTransformation(
        `${props.reportName}_download`,
        props.params,
        filters
      )

      const fetchBody = {
        download: true,
        dashboard_name: props.reportName,
        ...params,
        reportingParams: JSON.stringify({ ...params }),
        roles: 'app_user,all-access,default'
      }

      const resp = await LambdaFetch(
        'dashboard-trigger',
        'post',
        props.fetchInitialData.credentials.user.accessToken,
        JSON.stringify(fetchBody),
        '',
        props.fetchInitialData.credentials
      )


      if (resp.data.response.errorMessage) {
        window.alert(resp.data.response.errorMessage)
        setIsError(true)
        props.callback(null, resp.data.response.errorMessage)
        return
      }
      if(resp.data.response.statusCode === 200)  {
        props.fetchInitialData.createSnack(
          'File sent to print manager',
          'success',
          3000
        );
      }
    } catch (error) {
      setIsError(true)
      console.error(error)
      props.callback(null, error)
      return
    }
  }

  return (
    <MaterialReactTable
      columns={props.tableColumns.map((r) => {
        return { ...r, size: 1 };
      })}
      data={data}
      initialState={{
        ...props.initialState,
        showColumnFilters: true,
        density: 'compact',
      }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableGlobalFilter={false}
      enableStickyHeader
      enableSorting={false}
      enableFullScreenToggle={false}
      showSkeletons={true}
      muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
      onColumnFiltersChange={(a) => {
        setColumnFilters(a)
      }}
      onGlobalFilterChange={setGlobalFilter}
      onSortingChange={setSorting}
      muiTableBodyRowProps={({ row, table }) => {
        if (row.index % 2 === 0) {
          return { style: { background: '#F7F7F7' } };
        }
      }}
      count={pagination.pageSize * pagination.pageIndex + data.length}
      // rowCount={Math.min(data.length, pagination.pageSize)}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <IconButton onClick={handleExportData} icon={<CloudDownloadIcon />}>
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )}
      renderBottomToolbar={({ table }) => {
        return (
          <CustomFooter
            {...props}
            count={pagination.pageSize * pagination.pageIndex + data.length}
            page={pagination.pageIndex}
            rowsPerPage={pagination.pageSize}
            changeRowsPerPage={(num) =>
              setPagination({ ...pagination, pageSize: num })
            }
            changePage={(num) =>
              setPagination({ ...pagination, pageIndex: num })
            }
            textLabels={'of'}
            tableData={data}
          />
        );
      }}
    />
  );
};

export default ReactTableServerSide;


